import React from "react";
import "./ShareSocial.css";
import SocialMediaIcons from "./SocialMediaIcons";

// parent component is - ContactUs

const ShareButtons = () => {
  return (
    <div className="ShareSection">
      <SocialMediaIcons />
    </div>
  );
};

export default ShareButtons;
