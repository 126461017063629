import React, { useState } from "react";
import "./ShareSocial.css";

// parent component is - SocialMediaIcons

const CopyLink = () => {
  const [copied, setCopied] = useState("hidden");
  const pageUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pageUrl).then(() => {
      setCopied("copy-link-btn-text");
      setTimeout(() => setCopied("hidden"), 2000); // Reset the copied status after 2 seconds
    });
  };

  return (
    <button
      className="copy-button otherSocialButtons"
      onClick={copyToClipboard}
      title="Copy the room link"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#000000"
          fillRule="evenodd"
          d="M13,8 C13.5523,8 14,8.44772 14,9 L14,15 C14,15.5523 13.5523,16 13,16 L8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.44772 7.44772,8 8,8 L13,8 Z M7,0 C7.55228,0 8,0.447715 8,1 L9,1 C9.55228,1 10,1.44772 10,2 L11,2 C11.5523,2 12,2.44772 12,3 L12,7 L10,7 L10,5 L4,5 L4,12 L6,12 L6,14 L3,14 C2.44772,14 2,13.5523 2,13 L2,3 C2,2.44772 2.44772,2 3,2 L4,2 C4,1.44772 4.44772,1 5,1 L6,1 C6,0.447715 6.44772,0 7,0 Z M12,10 L9,10 L9,14 L12,14 L12,10 Z M7,2 C6.44772,2 6,2.44772 6,3 C6,3.55228 6.44772,4 7,4 C7.55228,4 8,3.55228 8,3 C8,2.44772 7.55228,2 7,2 Z"
        />
      </svg>
      <div className={copied}>Link Copied!</div>
    </button>
  );
};

export default CopyLink;
