import React, { useEffect, useState } from "react";
import "./NavPages.css";
import "./NavPagesAnimation.css";
import LoadingHexagon from "../../Loading/LoadingHexagon";
import ShareButtons from "../ContactUsSocial/ShareSocial/ShareButtons";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// parent component is - NavPlace

function ContactUs({ navCondition }) {
  const contactUs = "CONTACT US";

  const [isHidden, setIsHidden] = useState("hidden");

  useEffect(() => {
    navCondition === contactUs
      ? setIsHidden("NavPage ContactUs")
      : setIsHidden("hidden");
  }, [navCondition]);

  return (
    // className='NavPage'
    <section className={isHidden}>
      <div className="Nav_hide-hex">
        <LoadingHexagon />
      </div>

      <div className="NavHeaderPlace">
        <h3 className="h3_transform" title="Contact us | Topic">
          {contactUs}
        </h3>
      </div>

      <article className="NavText">
        <h4>
          You can connect with us on{" "}
          <Link
            className="NavText-socials"
            to="https://www.facebook.com/198Runners/"
            target="_blank"
          >
            Facebook
          </Link>
          ,{" "}
          <Link
            className="NavText-socials"
            to="https://www.instagram.com/198runners/"
            target="_blank"
          >
            Instagram
          </Link>
          ,{" "}
          <Link
            className="NavText-socials"
            to="https://www.youtube.com/@198runners"
            target="_blank"
          >
            Youtube
          </Link>
          , and{" "}
          <Link
            className="NavText-socials"
            to="https://www.tiktok.com/@198runners"
            target="_blank"
          >
            TikTok
          </Link>
          .
        </h4>
        <ShareButtons />
      </article>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
