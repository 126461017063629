import React, { useState, useEffect } from "react";
import "./Header.css";
import "./HeaderAnimation.css";
import VS from "../../../Images/vs.png";
import noUser from "../../../Images/noUser.png";
import yesUser from "../../../Images/yesUser.png";
import HeaderGamePlayersData from "./HeaderGamePlayersData/HeaderGamePlayersData";
import { useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function HeaderGame({ getSingleRoomCondition }) {
  const { room, isSuccess } = getSingleRoomCondition;

  const location = useLocation();
  const exactLocations = location.pathname.split("/");
  const roomId = location.pathname.split("/")[3];

  // STYLE --- Screen size/height applies for header game
  const [shortHeaderGame, setShortHeaderGame] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (window.innerHeight >= 800) {
        setShortHeaderGame(false);
      } else {
        setShortHeaderGame(true);
      }
    };

    // Initial check
    checkHeight();

    // Add an event listener to respond to changes in window height
    window.addEventListener("resize", checkHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);
  // STYLE --- Screen size/height applies for header game

  // get game players names and Ids
  const [gamePlayers, setGamePlayers] = useState({
    player1: undefined,
    player2: undefined,
    player3: undefined,
    player4: undefined,
  });

  const [gamePlayersOasis, setGamePlayersOasis] = useState({
    playerOasis1: undefined,
    playerOasis2: undefined,
    playerOasis3: undefined,
    playerOasis4: undefined,
  });

  const [thereIsUserImg, setThereIsUserImg] = useState({
    playerImg1: {
      img: noUser,
      title: "No Player",
    },
    playerImg2: {
      img: noUser,
      title: "No Player",
    },
    playerImg3: {
      img: noUser,
      title: "No Player",
    },
    playerImg4: {
      img: noUser,
      title: "No Player",
    },
  });

  useEffect(() => {
    const players =
      room !== undefined && isSuccess && !("message" in room)
        ? room.players.map((player) => player.name)
        : undefined;

    if (players !== undefined) {
      if (players.length === 1) {
        setGamePlayers({
          player1: players[0],
          player2: "Player 2",
          player3: "Player 3",
          player4: "Player 4",
        });

        setGamePlayersOasis({
          playerOasis1: room.whosTurn.helpOasis.oasis1,
          playerOasis2: undefined,
          playerOasis3: undefined,
          playerOasis4: undefined,
        });

        setThereIsUserImg({
          playerImg1: {
            img: yesUser,
            title: "Player 1",
          },
          playerImg2: {
            img: noUser,
            title: "No Player",
          },
          playerImg3: {
            img: noUser,
            title: "No Player",
          },
          playerImg4: {
            img: noUser,
            title: "No Player",
          },
        });
      } else if (players.length === 2) {
        setGamePlayers({
          player1: players[0],
          player2: players[1],
          player3: "Player 3",
          player4: "Player 4",
        });

        setGamePlayersOasis({
          playerOasis1: room.whosTurn.helpOasis.oasis1,
          playerOasis2: room.whosTurn.helpOasis.oasis2,
          playerOasis3: undefined,
          playerOasis4: undefined,
        });

        setThereIsUserImg({
          playerImg1: {
            img: yesUser,
            title: "Player 1",
          },
          playerImg2: {
            img: yesUser,
            title: "Player 2",
          },
          playerImg3: {
            img: noUser,
            title: "No Player",
          },
          playerImg4: {
            img: noUser,
            title: "No Player",
          },
        });
      } else if (players.length === 3) {
        setGamePlayers({
          player1: players[0],
          player2: players[1],
          player3: players[2],
          player4: "Player 4",
        });

        setGamePlayersOasis({
          playerOasis1: room.whosTurn.helpOasis.oasis1,
          playerOasis2: room.whosTurn.helpOasis.oasis2,
          playerOasis3: room.whosTurn.helpOasis.oasis3,
          playerOasis4: undefined,
        });

        setThereIsUserImg({
          playerImg1: {
            img: yesUser,
            title: "Player 1",
          },
          playerImg2: {
            img: yesUser,
            title: "Player 2",
          },
          playerImg3: {
            img: yesUser,
            title: "Player 3",
          },
          playerImg4: {
            img: noUser,
            title: "No Player",
          },
        });
      } else if (players.length === 4) {
        setGamePlayers({
          player1: players[0],
          player2: players[1],
          player3: players[2],
          player4: players[3],
        });

        setGamePlayersOasis({
          playerOasis1: room.whosTurn.helpOasis.oasis1,
          playerOasis2: room.whosTurn.helpOasis.oasis2,
          playerOasis3: room.whosTurn.helpOasis.oasis3,
          playerOasis4: room.whosTurn.helpOasis.oasis4,
        });

        setThereIsUserImg({
          playerImg1: {
            img: yesUser,
            title: "Player 1",
          },
          playerImg2: {
            img: yesUser,
            title: "Player 2",
          },
          playerImg3: {
            img: yesUser,
            title: "Player 3",
          },
          playerImg4: {
            img: yesUser,
            title: "Player 4",
          },
        });
      }
    }
  }, [room, roomId, isSuccess]);
  // get game players names

  const [hide, setHide] = useState(true);
  const [twoPlayer, setTwoPlayer] = useState(true);

  // if selected rooms to play, show header game
  useEffect(() => {
    exactLocations[2] !== "rooms" || exactLocations[2] === "undefined"
      ? setHide(true)
      : setHide(false);

    exactLocations[1] !== "single-player"
      ? setTwoPlayer(false)
      : setTwoPlayer(true);
  }, [exactLocations]);
  // if selected rooms to play, show header game

  const [activeplayer1, setActivePlayer1] = useState(false);
  const [activeplayer2, setActivePlayer2] = useState(false);
  const [activeplayer3, setActivePlayer3] = useState(false);
  const [activeplayer4, setActivePlayer4] = useState(false);
  const [gameOver, setGameOver] = useState(false);

  // Active player
  useEffect(() => {
    const allPlayerTurns =
      room !== undefined && isSuccess && !("message" in room)
        ? [
            room.whosTurn.player1.turn,
            room.whosTurn.player2.turn,
            room.whosTurn.player3.turn,
            room.whosTurn.player4.turn,
          ]
        : null;

    const isGameOver =
      room !== undefined && isSuccess && !("message" in room)
        ? room.whosTurn.gameOver
        : null;

    if (allPlayerTurns !== null) {
      setGameOver(isGameOver);

      if (allPlayerTurns[0]) {
        setActivePlayer1(true);
        setActivePlayer2(false);
        setActivePlayer3(false);
        setActivePlayer4(false);
      } else if (allPlayerTurns[1]) {
        setActivePlayer1(false);
        setActivePlayer2(true);
        setActivePlayer3(false);
        setActivePlayer4(false);
      } else if (allPlayerTurns[2]) {
        setActivePlayer1(false);
        setActivePlayer2(false);
        setActivePlayer3(true);
        setActivePlayer4(false);
      } else if (allPlayerTurns[3]) {
        setActivePlayer1(false);
        setActivePlayer2(false);
        setActivePlayer3(false);
        setActivePlayer4(true);
      }
    } else {
      setActivePlayer1(null);
      setActivePlayer2(null);
      setActivePlayer3(null);
      setActivePlayer4(null);
    }
  }, [room, isSuccess]);
  // Active player

  const [chosenPlayer, setChosenPlayer] = useState(undefined);

  const handlePlayerIds = (e) => {
    const playerId = e.target.title;

    //get player username
    const secondChild = e.target.children[0];
    const thirdChild = secondChild ? secondChild.children[1] : null;
    const fourthChild = thirdChild ? thirdChild.children[0] : null;
    const fifththChild = fourthChild ? fourthChild.textContent : null;
    //get player username

    const randomSuffix = Math.random().toString(36).substring(2, 5);
    setChosenPlayer({
      ...gamePlayersOasis[playerId],
      randomSuffix,
      fifththChild,
      roomId,
    });
  };

  return !hide ? (
    <header className="HeaderGameContainer">
      <div
        className={!shortHeaderGame ? "HeaderGameRegular" : "HeaderGameShort"}
      >
        <div
          title="playerOasis1"
          onClick={handlePlayerIds}
          className="HeaderGame-player1-container"
        >
          <div className="HeaderGame-player">
            <div className="HeaderGame-player-x">
              {activeplayer1 !== null && activeplayer1 && !gameOver ? (
                <div className="HeaderGame-player-active"></div>
              ) : null}

              <img
                className="HeaderGame-player-x-logo"
                src={thereIsUserImg.playerImg1.img}
                title={thereIsUserImg.playerImg1.title}
                alt={thereIsUserImg.playerImg1.title}
              />
            </div>

            <div className="HeaderGame-player-Title">
              <h5>{gamePlayers.player1}</h5>
            </div>
          </div>
        </div>

        <div
          title="playerOasis3"
          onClick={handlePlayerIds}
          className={!twoPlayer ? "HeaderGame-player2-container" : "hidden"}
        >
          <div className="HeaderGame-player">
            <div className="HeaderGame-player-x">
              {activeplayer3 !== null && activeplayer3 && !gameOver ? (
                <div className="HeaderGame-player-active"></div>
              ) : null}

              <img
                className="HeaderGame-player-x-logo"
                src={thereIsUserImg.playerImg3.img}
                title={thereIsUserImg.playerImg3.title}
                alt={thereIsUserImg.playerImg3.title}
              />
            </div>

            <div className="HeaderGame-player-Title">
              <h5>{gamePlayers.player3}</h5>
            </div>
          </div>
        </div>

        <div className="HeaderGame-players-vs">
          <img
            className="HeaderGame_logo_place_mainLogo_game"
            src={VS}
            title="versus"
            alt="versus"
          />
        </div>

        <div
          title="playerOasis2"
          onClick={handlePlayerIds}
          className="HeaderGame-player3-container"
        >
          <div className="HeaderGame-player">
            <div className="HeaderGame-player-x">
              {activeplayer2 !== null && activeplayer2 && !gameOver ? (
                <div className="HeaderGame-player-active"></div>
              ) : null}

              <img
                className="HeaderGame-player-x-logo"
                src={thereIsUserImg.playerImg2.img}
                title={thereIsUserImg.playerImg2.title}
                alt={thereIsUserImg.playerImg2.title}
              />
            </div>

            <div className="HeaderGame-player-Title">
              <h5>{gamePlayers.player2}</h5>
            </div>
          </div>
        </div>

        <div
          title="playerOasis4"
          onClick={handlePlayerIds}
          className={!twoPlayer ? "HeaderGame-player4-container" : "hidden"}
        >
          <div className="HeaderGame-player">
            <div className="HeaderGame-player-x">
              {activeplayer4 !== null && activeplayer4 && !gameOver ? (
                <div className="HeaderGame-player-active"></div>
              ) : null}

              <img
                className="HeaderGame-player-x-logo"
                src={thereIsUserImg.playerImg4.img}
                title={thereIsUserImg.playerImg4.title}
                alt={thereIsUserImg.playerImg4.title}
              />
            </div>

            <div className="HeaderGame-player-Title">
              <h5>{gamePlayers.player4}</h5>
            </div>
          </div>
        </div>
        <HeaderGamePlayersData chosenPlayerData={[chosenPlayer, room]} />
      </div>
    </header>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGame);
